/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import isArray from 'lodash/isArray';
import mergeWith from 'lodash/mergeWith';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import download from 'downloadjs';
import { Button } from '@welovedevs/ui';

// import JsonStub from './data/Resume-Juien-Dubois.json';
import JsonStubFR from './data/Resume-Julieta.json';
import JsonStubEN from './data/Resume-Julieta-EN.json';
import JsonStubES from './data/Resume-Julieta-ES.json';

import DeveloperProfile from './package';
import { ReactComponent as SaveIcon } from './package/assets/icons/drop_file.svg';

import { styles } from './app_styles';

const useStyles = createUseStyles(styles);
const mergeFunction = (objValue, srcValue, key) => {
    console.log({ objValue, srcValue, key });
    if (!objValue || isArray(objValue)) {
        return srcValue;
    }
    return undefined;
};

const mode = process.env.REACT_APP_MODE || 'readOnly';
// const mode = 'edit';
// const mode = 'readOnly';

function App() {
    const classes = useStyles();

    const [langage, setLangage] = useState('fr');
    const JsonStub = useMemo(() => {
    if (langage === 'tr') {
        return JsonStubES;
        }
    if (langage === 'en') {
        return JsonStubEN;
        }
        return JsonStubFR;
        }, [langage]);
        console.log(langage);
    const [data, setData] = useState(omit(JsonStub, 'resumeCustomization'));
    useEffect(() => {
        setData(omit(JsonStub, 'resumeCustomization'));
    }, [JsonStub]);

    const onEdit = useCallback((newData) => setData(mergeWith(cloneDeep(data), newData, mergeFunction)), [
        JSON.stringify(data)
    ]);
    const [customization, setCustomization] = useState(JsonStub.resumeCustomization || {});
    // useEffect(() => { setCustomization(JsonStub, 'resumeCustomization'); }, [JsonStub]);

    const onCustomizationChanged = useCallback(setCustomization, [data]);

    const handleClick = useCallback(async () => {
        // eslint-disable-next-line no-undef
        const blob = new Blob([JSON.stringify({ ...data, resumeCustomization: customization })], {
            type: 'text/plain; charset=utf-8'
        });
        download(
            blob,
            `${`Resume-${data?.basics?.name || 'Developer'}`.replace(' ', '-')}.json`,
            'text/plain; charset=utf-8'
        );
    }, [JSON.stringify(data), JSON.stringify(customization)]);

    const handleLangage = (flag) => {
        setLangage(`${flag.currentTarget.alt}`);
        console.log(flag.currentTarget.alt);
        console.log(langage, 'langage');
    };

    return (
        <>
        <img onClick={handleLangage} alt="tr" src="https://cdn.countryflags.com/thumbs/spain/flag-round-500.png" style={{ width: '45px', marginRight: '10px', marginTop: '5px', marginBottom: '5px', float: 'right' }} />
        <img onClick={handleLangage} alt="en" src="https://cdn.countryflags.com/thumbs/united-kingdom/flag-round-500.png" style={{ width: '45px', marginRight: '10px', marginTop: '5px', marginBottom: '5px', float: 'right' }} />
        <img onClick={handleLangage} alt="fr" src="https://cdn.countryflags.com/thumbs/france/flag-round-500.png" style={{ width: '45px', marginRight: '10px', marginTop: '5px', marginBottom: '5px', float: 'right' }} />
        <DeveloperProfile
            mode={mode}
            data={data}
            onEdit={onEdit}
            handleLangage={handleLangage}
            onCustomizationChanged={onCustomizationChanged}
            options={{
                // locale: 'tr',
                // side: 'back',
                locale: langage,
                apiKeys: {
                    giphy: process.env.REACT_APP_GIPHY
                },
                endpoints: {
                    devicons:
                        'https://firebasestorage.googleapis.com/v0/b/jechercheundev.appspot.com/o/technologies%2Ftechnologies_list.json?alt=media&token=459028ba-d9bc-4480-a3c4-88633afab7e2'
                },
                customization
            }}
            additionalNodes={{
                banner: {
                    actionsButtons: mode === 'edit' && (
                        <Button variant="outlined" onClick={handleClick} color={'light'}>
                            <SaveIcon className={classes.saveIcon} />
                            <FormattedMessage id="Profile.header.jsonResume.download" defaultMessage="Export" />
                        </Button>
                    )
                }
            }}
        />
        </>
    );
}

export default App;
